<template>
  <div>
    <div class="wrapper wrapper-mobilepadding page flex column center align-center">
      <h1>404</h1>
      <p>Sorry we couln't find the page you are searching for.</p>
      <a href="/" class="social-link">Back to Homepage</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>